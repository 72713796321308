var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-card-title", [_vm._v("Tutti-Duct Event Logs (Advanced)")]),
      _c("v-data-table", {
        attrs: {
          headers: _vm.logTableHeaders,
          items: _vm.logs,
          "items-per-page": 10
        },
        scopedSlots: _vm._u([
          {
            key: "item.sent",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.eid) + " "),
                _c("vue-json-pretty", {
                  staticStyle: { "font-size": "0.6em" },
                  attrs: { data: item.sent, deep: 1 }
                })
              ]
            }
          },
          {
            key: "item.received",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("vue-json-pretty", {
                  staticStyle: { "font-size": "0.6em" },
                  attrs: { data: item.received, deep: 2 }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }